import React, { useRef, useState } from "react";
import Background from "../assets/bg.png";
import PauseIcon from "../assets/icons/pause.svg";
import PlayIcon from "../assets/icons/play.svg";
import philvideo from "../assets/videophil.mp4";

const Video = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPaused, setIsPaused] = useState(false);
  const [progress, setProgress] = useState(0);

  // Handle pause/play toggle
  const handlePausePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPaused(false);
      } else {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }
  };

  // Update progress bar as video plays
  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentProgress =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(currentProgress);
    }
  };
  return (
    <div
      className="lg:hidden mt-4 w-full bg-gradient-to-t from-[#CF4605] to-[#017FC4] flex justify-center flex-col p-4  border-1 border-l-[0.5px] border-t-[0.5px] border-l-[rgba(255,255,255,0.3)]   border-t-[rgba(255,255,255,0.5)]  shadow-[7px_7px_7px_rgba(0,0,0,4)]"
      style={{
        backgroundImage: `url(${Background}), linear-gradient(to top, #CF4605, #017FC4)`,
        backgroundSize: "cover", // Ensures the image fits the div
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center", // Keeps the image centered
      }}
    >
      <p className="text-white text-center text-shadow font-title text-2xl">
        Monday on <span className="font-drphil">Dr. Phil</span>
      </p>
      <div className="mt-2 flex justify-center items-center px-4 flex-col">
        <video
          ref={videoRef}
          src={philvideo}
          autoPlay
          loop
          muted={false}
          className="h-[180px] object-cover"
          onTimeUpdate={handleTimeUpdate}
        />
        <div className="flex items-center w-full max-w-[300px] mt-4">
          {/* Pause/Play Button */}
          <button
            onClick={handlePausePlay}
            className="bg-gray-800 text-white px-3 py-1 mr-2 rounded border-1 border-l-[0.5px] border-t-[0.5px] border-l-[rgba(255,255,255,0.2)]   border-t-[rgba(255,255,255,0.3)] "
          >
            <img
              src={isPaused ? PlayIcon : PauseIcon}
              alt={isPaused ? "Play" : "Pause"}
              className="w-6 h-6"
            />
          </button>
          {/* Progress Bar */}
          <div className="relative w-full bg-gray-800 h-2 ">
            <div
              className="absolute top-0 left-0 bg-yellow-500 h-full"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
