import React, { useState, useEffect } from "react";
import LgScreen from "./lgscreen";
import SmScreen from "./smscreen";

const Home = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  // Update state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {/* Conditionally render based on screen size */}
      {isLargeScreen ? <LgScreen /> : <SmScreen />}
    </div>
  );
};

export default Home;
