import React, { useRef, useState, useEffect } from "react";
import Background from "../assets/bg.png";
import PauseIcon from "../assets/icons/pause.svg";
import PlayIcon from "../assets/icons/play.svg";
import philvideo from "../assets/videophil.mp4";
import Crossarms from "../assets/crossarms.png";

const Videolg = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPaused, setIsPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUserInteracted, setIsUserInteracted] = useState(false);

  // Enable video play after user interaction
  useEffect(() => {
    const handleUserInteraction = (event: MouseEvent) => {
      // Ignore clicks on the play/pause button
      if ((event.target as HTMLElement).closest(".play-button")) return;

      if (!isUserInteracted) {
        setIsUserInteracted(true);
        if (videoRef.current) {
          videoRef.current.muted = false; // Unmute the video
          videoRef.current.play().catch((error) => {
            console.error("Video autoplay failed:", error);
          });
          setIsPaused(false);
        }
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleUserInteraction);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("click", handleUserInteraction);
    };
  }, [isUserInteracted]);

  // Handle play/pause toggle
  const handlePausePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play().catch((error) => {
          console.error("Video play failed:", error);
        });
        setIsPaused(false);
      } else {
        videoRef.current.pause();
        setIsPaused(true);
      }
    }
  };

  // Update progress bar as the video plays
  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentProgress =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(currentProgress);
    }
  };

  return (
    <div
      className="hidden lg:flex mt-4 w-full h-80 bg-gradient-to-t from-[#CF4605] to-[#017FC4] border-1 border-l-[0.5px] border-t-[0.5px] border-l-[rgba(255,255,255,0.3)] border-t-[rgba(255,255,255,0.5)] shadow-[7px_7px_7px_rgba(0,0,0,4)]"
      style={{
        backgroundImage: `url(${Background}), linear-gradient(to top, #CF4605, #017FC4)`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {/* Left Section */}
      <div className="flex flex-col justify-end w-1/3">
        <img
          src={Crossarms}
          alt="Dr. Phil Cross-Armed"
          className="w-auto h-[100%] object-contain"
        />
      </div>

      {/* Middle Section (Video) */}
      <div className="flex flex-col justify-center items-center w-1/3 px-4">
        <div className="flex justify-center items-center flex-col w-full">
          <p className="text-white text-center text-shadow font-title text-2xl">
            Monday on <span className="font-drphil">Dr. Phil:</span>
          </p>
          <video
            ref={videoRef}
            src={philvideo}
            loop
            muted
            className="h-1/2 object-cover"
            onTimeUpdate={handleTimeUpdate}
          />
          <div className="flex items-center w-full max-w-[230px] mt-4">
            {/* Pause/Play Button */}
            <button
              onClick={handlePausePlay}
              className="play-button bg-gray-800 text-white px-3 py-1 mr-2 rounded border-1 border-l-[0.5px] border-t-[0.5px] border-l-[rgba(255,255,255,0.2)] border-t-[rgba(255,255,255,0.3)]"
            >
              <img
                src={isPaused ? PlayIcon : PauseIcon}
                alt={isPaused ? "Play" : "Pause"}
                className="w-6 h-6"
              />
            </button>
            {/* Progress Bar */}
            <div className="relative w-full bg-gray-800 h-2">
              <div
                className="absolute top-0 left-0 bg-yellow-500 h-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex flex-col justify-center w-1/3 items-center p-16">
        <p className="text-white text-2xl mb-2 w-full font-title">
          <p>We are pumping our bags with:</p>
          <span className="font-drphil ">$DRPHIL</span>
        </p>
      </div>
    </div>
  );
};

export default Videolg;
