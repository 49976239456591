import React, { useEffect } from "react";
import "./App.css";
import Home from "./pages/home";
import Navbar from "./components/Navbar";
import Hotjar from "@hotjar/browser";

function App() {
  useEffect(() => {
    const siteId = 5223883;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  return (
    <div>
      <Navbar />
      <Home />
    </div>
  );
}

export default App;
