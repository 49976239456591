import { useState } from "react";
import standing from "../assets/standing.png";
import Newshow from "../assets/newshow.png";
import Banner from "../assets/banner.png";
import Uncensored from "../assets/uncensored.png";
import Video from "../components/Video";
import Videolg from "../components/Videolg";
import Tallbanner from "../assets/tallbanner.png";
import Grid from "../components/Grid";
import Smallbg from "../assets/smallbg.png";
import CrossArmsSecond from "../assets/crossarmssecond.png";
import Support from "../assets/support.png";
import Vertical1 from "../assets/vertical-1.png";
import Vertical2 from "../assets/vertical-2.png";
import Vertical3 from "../assets/vertical-3.png";

const LgScreen = () => {
  const [username, setUsername] = useState("");
  const [question, setQuestion] = useState("");
  const [error, setError] = useState("");

  return (
    <div>
      <div className="flex w-full lg:px-64 text-shadow mt-6">
        {/* Left Section */}
        <div className="flex-[4] pr-4 ">
          {/* Top Banner */}
          <div className="border-1 border-l-[0.5px] lg:hidden border-l-[rgba(255,255,255,0.3)] border-t-[0.5px] border-t-[rgba(255,255,255,0.5)] border-white shadow-[7px_7px_7px_rgba(0,0,0,4)]">
            <img src={Banner} alt="Banner" />
          </div>
          {/* Videos */}
          <Video />
          <Videolg />
          {/* 50/50 */}
          <div className="flex flex-row w-full gap-4 cursor-custom">
            {/* Ask Dr. Phil Section */}
            <div
              className="flex-1 relative bg-gradient-to-t from-[#0054A2] to-[#249DC1] text-white mt-16 p-4 border-l-[0.5px] border-l-[rgba(255,255,255,0.3)] border-t-[0.5px] border-t-[rgba(255,255,255,0.5)] shadow-[7px_7px_7px_rgba(0,0,0,4)]"
              style={{
                backgroundImage: `url(${Smallbg}), linear-gradient(to top, #CF4605, #017FC4)`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <p className="mb-2 text-center text-2xl">
                Ask
                <span className="font-drphil pl-2">Dr. Phil</span>
              </p>
              <div className="relative w-full">
                <img
                  src={standing}
                  alt="Dr. Phil Standing"
                  className="absolute -left-11 top-11 transform -translate-y-36 h-[290px]"
                />
                {/* Inputs */}
                <input
                  type="text"
                  name="username"
                  id="username"
                  placeholder="@TwitterHandle"
                  className="mb-2 w-full h-8 p-4 text-white border-[rgba(255,255,255,0.3)] border-[0.5px] bg-title2 focus:outline-none"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <textarea
                  name="question"
                  id="question"
                  placeholder="Ask Dr Phil a question..."
                  className="w-full h-48 p-4 resize-none text-white border-[rgba(255,255,255,0.3)] border-[0.5px] bg-title2 focus:outline-none"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                ></textarea>
                {/* Error Message */}
                {error && <p className="text-red-500 mt-2">{error}</p>}
                {/* Submit Button */}
                <button
                  className="absolute bottom-3 right-1 bg-gray-800 text-white px-4 py-2 rounded-lg border-1 border-l-[0.5px] border-t-[0.5px] border-l-[rgba(255,255,255,0.5)] border-t-[rgba(255,255,255,0.5)]"
                  onClick={() => {
                    const storedQuestions = JSON.parse(
                      localStorage.getItem("questions") || "[]"
                    );

                    // Validation logic
                    if (username.length <= 4) {
                      setError("Username must be more than 4 characters.");
                      return;
                    }
                    if (!question.trim()) {
                      setError("Question cannot be empty.");
                      return;
                    }
                    if (storedQuestions.length >= 5) {
                      setError("You can only submit up to 5 questions.");
                      return;
                    }

                    setError(""); // Clear error

                    // Add new question
                    const newQuestion = { username, question };
                    const updatedQuestions = [...storedQuestions, newQuestion];
                    localStorage.setItem(
                      "questions",
                      JSON.stringify(updatedQuestions)
                    );

                    // Reset inputs
                    setUsername("");
                    setQuestion("");

                    alert("Your question has been submitted!");
                  }}
                >
                  Submit
                </button>
              </div>
            </div>

            {/* Phil Information Section */}
            <div
              className="p-4 flex-1 text-white mt-16 border-1 border-l-[0.5px] border-t-[0.5px] border-l-[rgba(255,255,255,0.3)] border-t-[rgba(255,255,255,0.3)] shadow-[7px_7px_7px_rgba(0,0,0,4)]"
              style={{
                backgroundImage: `url(${Smallbg}), linear-gradient(to top, #CF4605, #017FC4)`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <p className="mb-2 text-center text-2xl">
                Top
                <span className="font-drphil pl-2">Audience </span> Members
              </p>
              <div className="mb-2 w-full h-[82%] max-h-[230px] p-4 text-white border-[rgba(255,255,255,0.3)] border-[0.5px] bg-title2 focus:outline-none overflow-y-auto hide-scrollbar">
                <table className="w-full text-left border-collapse">
                  <thead className="sticky -top-4 bg-title2">
                    <tr>
                      <th className="py-2 px-4 border-b border-[rgba(255,255,255,0.3)]">
                        Rank
                      </th>
                      <th className="py-2 px-4 border-b border-[rgba(255,255,255,0.3)]">
                        Username
                      </th>
                      <th className="py-2 px-4 border-b border-[rgba(255,255,255,0.3)]">
                        Ratings
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from({ length: 0 }, (_, index) => (
                      <tr
                        key={index}
                        className="even:bg-[rgba(255,255,255,0.1)] odd:bg-[rgba(255,255,255,0.05)]"
                      >
                        <td className="py-2 px-4 border-b border-[rgba(255,255,255,0.3)]">
                          {index + 1}
                        </td>
                        <td className="py-2 px-4 border-b border-[rgba(255,255,255,0.3)]">
                          @username{index + 1}
                        </td>
                        <td className="py-2 px-4 border-b border-[rgba(255,255,255,0.3)]">
                          {Math.floor(Math.random() * 1000)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>{" "}
            </div>
          </div>

          <div className="bg-orange text-white w-full mt-16 p-4 flex flex-row justify-between border-1 border-l-[0.5px] border-t-[0.5px] border-l-[rgba(255,255,255,0.3)] border-t-[rgba(255,255,255,0.3)] shadow-[7px_7px_7px_rgba(0,0,0,4)]">
            <div>
              <p>BE IN THE</p> <p>AUDIENCE</p>
            </div>
            <div>
              <p>Click Here or call</p>
              <p>323-461-PHIL</p>
            </div>
          </div>
          <Grid />
          <div className="grid grid-cols-3 gap-4 mt-4">
            {/* New Show Section */}
            <div className="grid grid-rows-3 gap-2">
              <div className="border-1 border-l-[0.5px] border-l-[rgba(255,255,255,0.3)] border-white shadow-[7px_7px_7px_rgba(0,0,0,4)] bg-gradient-to-t ">
                <img src={Vertical1} alt="New Show" className="h-full" />
              </div>
              <div className="border-1 border-l-[0.5px] border-l-[rgba(255,255,255,0.3)] border-white shadow-[7px_7px_7px_rgba(0,0,0,4)] bg-gradient-to-t ">
                <img src={Vertical2} alt="New Show" className="h-full" />
              </div>
              <div className="border-1 border-l-[0.5px] border-l-[rgba(255,255,255,0.3)] border-white shadow-[7px_7px_7px_rgba(0,0,0,4)] bg-gradient-to-t ">
                <img src={Vertical3} alt="New Show" className="h-full" />
              </div>
            </div>
            <div className="border-1 border-l-[0.5px] border-l-[rgba(255,255,255,0.3)] border-white shadow-[7px_7px_7px_rgba(0,0,0,4)] bg-gradient-to-t ">
              <img src={Support} alt="New Show" className="h-full" />
            </div>
            <div className="border-1 border-l-[0.5px] border-l-[rgba(255,255,255,0.3)] border-white shadow-[7px_7px_7px_rgba(0,0,0,4)] bg-gradient-to-t ">
              <img src={Uncensored} alt="New Show" className="h-full" />
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="flex-[0.5] h-full flex justify-start items-start mt-4">
          <img
            src={Tallbanner}
            alt="Tall Banner"
            className="w-full h-auto object-cover"
          />
        </div>
      </div>
      <div>
        {/* bottom */}
        <div className="px-40  flex items-center  flex-col ">
          <div className="flex flex-row items-center  text-white text-center pt-14">
            <p>
              {["HOME", "ABOUT", "DR.PHIL", "SHOW", "ARCHIVE"].map(
                (word, index) => (
                  <span key={index} className="underline mx-1 no-shadow">
                    {word}
                  </span>
                )
              )}
            </p>
          </div>
          <div className="flex flex-row items-center  text-white  text-center">
            <p>
              {[
                "SHOWS",
                "THIS",
                "WEEK",
                "STORE",
                "BE ON THE SHOW",
                "IN THE NEWS",
              ].map((word, index) => (
                <span key={index} className="underline mx-1 no-shadow">
                  {word}
                </span>
              ))}
            </p>
          </div>
        </div>
        <p className="no-shadow  text-white text-center mt-4 pb-10">
          COPYRIGHT ® 2004
        </p>
      </div>
    </div>
  );
};

export default LgScreen;
