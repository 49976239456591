import standing from "../assets/standing.png";
import Newshow from "../assets/newshow.png";
import Hidden from "../assets/hidden.png";
import Banner from "../assets/banner.png";
import Uncensored from "../assets/uncensored.png";
import Video from "../components/Video";

import Smallbg from "../assets/smallbg.png";

const Home = () => {
  return (
    <div className="block lg:hidden pt-4 px-10 w-full lg:px-40 relative h-screen text-shadow ">
      {" "}
      <div className="mt-4 border-1 border-l-[0.5px] lg:hidden border-l-[rgba(255,255,255,0.3)] border-t-[0.5px]  border-t-[rgba(255,255,255,0.5)]  border-white shadow-[7px_7px_7px_rgba(0,0,0,4)] ">
        <img src={Banner} alt="" />
        <div />
      </div>
      <Video />
      {/* Ask Dr. Phil Section */}
      <div
        className="flex-1 relative bg-gradient-to-t from-[#0054A2] to-[#249DC1] text-white mt-16 p-4 border-l-[0.5px] border-l-[rgba(255,255,255,0.3)] border-t-[0.5px] border-t-[rgba(255,255,255,0.5)] shadow-[7px_7px_7px_rgba(0,0,0,4)]"
        style={{
          backgroundImage: `url(${Smallbg}), linear-gradient(to top, #CF4605, #017FC4)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <p className="mb-2 text-center text-2xl">
          Ask
          <span className="font-drphil pl-2">Dr. Phil</span>
        </p>
        <div className="relative w-full">
          <img
            src={standing}
            alt="Dr. Phil Standing"
            className="absolute -left-11 top-11 transform -translate-y-36 h-[290px]"
          />
          {/* Textarea */}
          <input
            type="text"
            name="askDrPhil"
            id="askDrPhil"
            placeholder="Twitter Handle"
            className="mb-2 w-full h-8 p-4 text-white border-[rgba(255,255,255,0.3)] border-[0.5px] bg-title2 focus:outline-none"
          />
          <textarea
            name="askDrPhil"
            id="askDrPhil"
            placeholder="Ask Dr Phil a question..."
            className="w-full h-48 p-4 resize-none text-white border-[rgba(255,255,255,0.3)] border-[0.5px] bg-title2 focus:outline-none"
          ></textarea>
          <button className="absolute bottom-3 right-1 bg-gray-800 text-white px-4 py-2 rounded-lg border-1 border-l-[0.5px] border-t-[0.5px] border-l-[rgba(255,255,255,0.5)] border-t-[rgba(255,255,255,0.5)]">
            Submit
          </button>
        </div>
      </div>
      <div className="mt-6 border-1 border-l-[0.5px]  border-l-[rgba(255,255,255,0.3)]  border-white shadow-[7px_7px_7px_rgba(0,0,0,4)] ">
        {/* <div className="w-full bg-slate-500 mt-4"> */}
        <div className="bg-orange text-white h-12 flex justify-center items-end border-t-[0.5px]  border-t-[rgba(255,255,255,0.5)] font-title text-4xl">
          BE ON THE SHOW
        </div>
        <div className="bg-subtitle h-8 flex px-4 items-end justify-center text-xl">
          Tell Us Your Story!
        </div>
        <div
          className="px-4 pt-2 text-white bg-gradient-to-t from-[#017FC4] to-[#0054A2]"
          style={{
            backgroundImage: `url(${Smallbg}), linear-gradient(to top, #CF4605, #017FC4)`,
            backgroundSize: "cover", // Ensures the image fits the div
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center", // Keeps the image centered
          }}
        >
          <ul>
            <li>Rich In-Low Woes?</li>
            <li>Lying about Finances?</li>
            <li>Love-Obsessed Tean?</li>
            <li>Acting Like You're Rich?</li>
          </ul>
        </div>
      </div>
      <div className="mt-6 border-1 border-l-[0.5px]  border-l-[rgba(255,255,255,0.3)]  border-white shadow-[7px_7px_7px_rgba(0,0,0,4)] bg-gradient-to-t from-[#017FC4] to-[#0054A2]">
        <img src={Newshow} alt="" />
        <div />
      </div>
      <div className="w-full  mt-4 border-l-[0.5px]  border-l-[rgba(255,255,255,0.3)]   shadow-[7px_7px_7px_rgba(0,0,0,4)]">
        <div className="bg-orange text-white h-12 flex justify-center items-end border-t-[0.5px]  border-t-[rgba(255,255,255,0.5)] font-title text-4xl">
          TAKE A POLE!
        </div>
        <div className="bg-subtitle h-8 flex px-4 items-end justify-center text-xl">
          Ask Dr Phil your sexual questions!
        </div>
        <div
          className="px-4 pt-2 flex flex-row items-start bg-gradient-to-t from-[#017FC4] to-[#0054A2] text-white"
          style={{
            backgroundImage: `url(${Smallbg}), linear-gradient(to top, #CF4605, #017FC4)`,
            backgroundSize: "cover", // Ensures the image fits the div
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center", // Keeps the image centered
          }}
        >
          <img src={Hidden} alt="" className=" w-auto h-16 flex-shrink-0" />
          {/* Text takes remaining space */}
          <p className="ml-4 flex-grow ">
            Wonder if it's normal for your husband to want Hidden? Vote now!
          </p>
        </div>
      </div>
      <div className="bg-subtitle w-full mt-4 p-4 flex flex-row justify-between border-1 border-l-[0.5px] border-t-[0.5px] border-l-[rgba(255,255,255,0.3)] border-t-[rgba(255,255,255,0.3)] shadow-[7px_7px_7px_rgba(0,0,0,4)]">
        <div>
          <p>BE IN THE</p> <p>AUDIENCE</p>
        </div>
        <div>
          <p>Click Here or call</p>
          <p>323-461-PHIL</p>
        </div>
      </div>
      <div className="mt-4 border-1 border-l-[0.5px] border-t-[0.5px]  border-t-[rgba(255,255,255,0.5)]  border-l-[rgba(255,255,255,0.3)]  border-white shadow-[7px_7px_7px_rgba(0,0,0,4)] bg-gradient-to-t ">
        <img src={Uncensored} alt="" />
        <div />
      </div>
      <div className="flex flex-row items-center  text-white text-center pt-10">
        <p>
          {["HOME", "ABOUT", "DR.PHIL", "SHOW", "ARCHIVE"].map(
            (word, index) => (
              <span key={index} className="underline mx-1 no-shadow">
                {word}
              </span>
            )
          )}
        </p>
      </div>
      <div className="flex flex-row items-center  text-white  text-center">
        <p>
          {[
            "SHOWS",
            "THIS",
            "WEEK",
            "STORE",
            "BE ON THE SHOW",
            "IN THE NEWS",
          ].map((word, index) => (
            <span key={index} className="underline mx-1 no-shadow">
              {word}
            </span>
          ))}
        </p>
      </div>
      <p className="no-shadow  text-white text-center pb-8 pt-2">
        COPYRIGHT ® 2004
      </p>
    </div>
  );
};

export default Home;
