import React, { useState } from "react";
import Logo from "../assets/logo.png";
import Smallbg from "../assets/smallbg.png";

// Import sound files
import YoureUgly from "../assets/sounds/1.youre-ugly.mp3";
import DrPhil from "../assets/sounds/2.dr-phil.mp3";
import ShutUp from "../assets/sounds/3.shut-up.mp3";
import VrDream from "../assets/sounds/4.vr-dream.mp3";
import Leash from "../assets/sounds/5.leash.mp3";
import Audience from "../assets/sounds/6.audience.mp3";
import Dirty from "../assets/sounds/7.dirty.mp3";
import Sthu from "../assets/sounds/8.sthu.mp3";

const Navbar = () => {
  // Map buttons to sound files
  const buttonSounds = [
    { name: "PAY ME NOW", sound: YoureUgly },
    { name: "KNOCK KNOCK", sound: DrPhil },
    { name: "QUIET ON SET", sound: ShutUp },
    { name: "VR DREAMS", sound: VrDream },
    { name: "RELATION TIPS", sound: Leash },
    { name: "MEET THE AUDIENCE", sound: Audience },
    { name: "FEELING OFF?", sound: Dirty },
    { name: "PHIL RELAX", sound: Sthu },
  ];

  // State to manage currently playing audio
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
    null
  );

  // Function to play the sound
  const playSound = (sound: string) => {
    // Stop the current audio if playing
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
    }

    // Create and play new audio
    const audio = new Audio(sound);
    audio.play();
    setCurrentAudio(audio);
  };

  return (
    <div className="relative w-screen h-40 flex flex-col items-center">
      {/* Orange Background */}
      <div className="absolute overflow-hidden rounded-full -bottom-4 lg:-bottom-4 w-[calc(100%-80px)] lg:w-[calc(100%-410px)] lg:h-16 h-12 bg-orange shadow-[7px_7px_7px_rgba(0,0,0,4)] z-10 mx-auto">
        <div className="relative w-full h-full flex items-end overflow-hidden">
          {/* Hide marquee on small screens */}
          <div className="hidden lg:flex marquee space-x-4 text-white mb-1 text-shadow">
            {buttonSounds.map((item, index) => (
              <React.Fragment key={index}>
                <p className="whitespace-nowrap">{item.name}</p>
                <div className="bg-blue-600 text-white px-2 rounded shadow-[2px_2px_2px_rgba(0,0,0,4)]">
                  ASK DR PHIL
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      {/* Blue Background (Overlaps Orange) */}
      <div className="absolute bottom-4 lg:bottom-4 w-[calc(100%-80px)] lg:w-[calc(100%-410px)] h-14 bg-gradient-to-t lg:h-16 from-[#017FC4] to-[#0046a2] rounded-3xl shadow-[7px_7px_7px_rgba(0,0,0,4)] z-20 mx-auto border-t-[rgba(255,255,255,0.1)] border-t-[0.5px]">
        {/* Hoverable Rectangles */}
        <div className="hidden lg:flex absolute w-full h-full items-center justify-center gap-4 px-4 text-end">
          {buttonSounds.map((button, index) => (
            <div
              key={index}
              className="border-1 border-l-[0.5px] border-l-[rgba(255,255,255,0.3)] border-t-[0.5px] border-t-[rgba(255,255,255,0.5)] border-white w-24 h-11 lg:w-28 lg:h-10  text-white flex items-center justify-end px-2 lg:px-4 shadow-[2px_3px_3px_rgba(0,0,0,2)]  hover:scale-110  transition-all duration-300 cursor-pointer"
              style={{
                backgroundImage: `url(${Smallbg}), linear-gradient(to top, #CF4605, #017FC4)`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
              onClick={() => playSound(button.sound)} // Play sound on click
            >
              <p className="text-sm lg:text-base font-semibold">
                {button.name}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Logo */}
      <img
        src={Logo}
        alt="Dr. Phil Logo"
        className="absolute top-2 lg:top-4 lg:left-48 lg:h-32 z-30 h-24"
      />
    </div>
  );
};

export default Navbar;
