import Smallbg from "../assets/smallbg.png";
import Hidden from "../assets/hidden.png";
import Sad from "../assets/sad.png";

const Grid = () => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        {/* Be On the Show Section */}

        <div className="mt-4 border-1 border-l-[0.5px] border-l-[rgba(255,255,255,0.3)] border-white shadow-[7px_7px_7px_rgba(0,0,0,4)]">
          <div className="bg-orange text-white h-12 flex justify-center items-end border-t-[0.5px] border-t-[rgba(255,255,255,0.5)] font-title text-4xl">
            BE ON THE SHOW
          </div>
          <div className="bg-subtitle h-8 flex px-4 items-end justify-center text-xl">
            Tell Us Your Story!
          </div>
          <div
            className="px-4 pt-2 text-white bg-gradient-to-t h-[76%]"
            style={{
              backgroundImage: `url(${Smallbg}), linear-gradient(to top, #CF4605, #017FC4)`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <ul>
              <li>Told your parents to buy Ethereum?</li>
              <li>Lost your life savings?</li>
              <li>Held a coin that went to zero?</li>
              <li>Lying about your crypto gains?</li>
              <li>Acting Like You're Rich?</li>
              <li>Checking DexScanner 24/7?</li>
            </ul>
          </div>
        </div>
        {/* Take a Poll Section */}
        <div className="grid grid-row-2">
          <div className="grid grid-cols-2 gap-4">
            <div className="w-full mt-4 border-l-[0.5px] border-l-[rgba(255,255,255,0.3)] shadow-[7px_7px_7px_rgba(0,0,0,4)]">
              <div className="bg-orange text-white h-12 flex justify-center items-end border-t-[0.5px] border-t-[rgba(255,255,255,0.5)] font-title text-4xl">
                LOSING MONEY
              </div>
              <div className="bg-subtitle h-8 flex px-4 items-end justify-center text-lg">
                Ask Dr Phil your questions!
              </div>
              <div
                className="px-4 pt-2 flex flex-row items-start bg-gradient-to-t h-[66%] from-[#017FC4] to-[#0054A2] text-white"
                style={{
                  backgroundImage: `url(${Smallbg}), linear-gradient(to top, #CF4605, #017FC4)`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <img
                  src={Hidden}
                  alt="Poll Image"
                  className="w-auto h-16 flex-shrink-0"
                />
                <p className="ml-4 flex-grow">
                  Wonder if it's normal for your husband to want Hidden? Vote
                  now!
                </p>
              </div>
            </div>
            {/* Take a Poll Section */}
            <div className="w-full mt-4 border-l-[0.5px] border-l-[rgba(255,255,255,0.3)] shadow-[7px_7px_7px_rgba(0,0,0,4)]">
              <div className="bg-orange text-white h-12 flex justify-center items-end border-t-[0.5px] border-t-[rgba(255,255,255,0.5)] font-title text-4xl">
                TAKE A POLL!
              </div>
              <div className="bg-subtitle h-8 flex px-4 items-end justify-center text-lg">
                Ask Dr Phil your questions!
              </div>
              <div
                className="px-4 pt-2 flex flex-row items-start bg-gradient-to-t from-[#017FC4] to-[#0054A2] text-white"
                style={{
                  backgroundImage: `url(${Smallbg}), linear-gradient(to top, #CF4605, #017FC4)`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <img
                  src={Sad}
                  alt="Poll Image"
                  className="w-auto h-16 flex-shrink-0"
                />
                <p className="ml-4 flex-grow">
                  Wonder if it's normal for your husband to want (insert term)?
                  Vote now!
                </p>
              </div>
            </div>
          </div>
          <div
            className="bg-subtitle text-white w-full mt-4 p-4 flex flex-row justify-between border-1 border-l-[0.5px] border-t-[0.5px] border-l-[rgba(255,255,255,0.3)] border-t-[rgba(255,255,255,0.3)] shadow-[7px_7px_7px_rgba(0,0,0,4)]"
            style={{
              backgroundImage: `url(${Smallbg}), linear-gradient(to top, #CF4605, #017FC4)`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div>
              <p>BE IN THE</p> <p>AUDIENCE</p>
            </div>
            <div>
              <p>Click Here or call</p>
              <p>323-461-PHIL</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grid;
